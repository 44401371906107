<template>
  <div class="content-box" id="picture" v-show="isShow">
    <div class="header">
      <div class="header-top">
        <span>电子收据</span>
        <div class="no-box">
          <span>No.&nbsp;</span>
          <span>{{ receiptData.pjbh }}</span>
        </div>
      </div>
      <div class="line-style"></div>
      <div class="header-bottom">
        <div>{{ receiptData.zhmc }}</div>
        <div>{{ receiptData.jfsj }}</div>
      </div>
    </div>
    <div class="detail-box">
      <div>
        <div>交款单位</div>
        <div class="text">{{ receiptData.jkdw }}</div>
      </div>
      <div>
        <div>收费款项</div>
        <div class="text">{{ receiptData.title }}</div>
      </div>
      <div>
        <div>收款方式</div>
        <div class="text">{{ getDicDataFun("skfs", receiptData.skfs) }}</div>
        <div style="margin: 0 10px">人民币（大写）</div>
        <div class="text">{{ receiptData.ssje }}</div>
        <div>￥</div>
      </div>
      <div>
        <div>备注</div>
        <div class="text">{{ receiptData.bz }}</div>
      </div>
    </div>
    <div class="bottom-box">
      <div>收款人：{{ receiptData.skr }}</div>
      <div>经办人：{{ receiptData.jbr }}</div>
      <div>收款单位（盖章）：</div>
    </div>
    <div>
      <img class="seal-box" :src="receiptData.financial_seal" />
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { ImagePreview } from "vant";
import { getReceiptApi, getDicTableData } from "@/reuqest/api.js";
export default {
  props: ["zdid"],
  data() {
    return {
      receiptData: {},
      imgUrl: "",
      isShow: true,
      allDicData: {},//字典数据
    };
  },
  created() {
    getDicTableData("skfs")
      .then((res) => {
        this.allDicData.skfsDic = res.data;
      })
      .catch(() => {});
  },
  mounted() {
    this.init(this.zdid);
  },
  methods: {
    init(zdid) {
      const loadingToast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      getReceiptApi({
        id: zdid,
      }).then((res) => {
        this.receiptData = res.data;
        this.receiptData.ssje = this.menoyToUppercase(this.receiptData.ssje);
        this.saveImage();
        loadingToast.clear();
      });
    },
    getDicDataFun(key, val) {
      let text = "";
      if(this.allDicData[`${key}Dic`] && val){
          this.allDicData[`${key}Dic`].forEach(item => {
          if(val && item.value == val){
            text = item.label
          }
        });
      }
      return text;
    },
    menoyToUppercase(money) {
      //汉字的数字
      let cnNums = new Array(
        "零",
        "壹",
        "贰",
        "叁",
        "肆",
        "伍",
        "陆",
        "柒",
        "捌",
        "玖"
      );
      //基本单位
      let cnIntRadice = new Array("", "拾", "佰", "仟");
      //对应整数部分扩展单位
      let cnIntUnits = new Array("", "万", "亿", "兆");
      //对应小数部分单位
      let cnDecUnits = new Array("角", "分", "毫", "厘");
      //整数金额时后面跟的字符
      let cnInteger = "整";
      //整型完以后的单位
      let cnIntLast = "圆";
      //最大处理的数字
      let maxNum = 999999999999999.9999;
      //金额整数部分
      let integerNum;
      //金额小数部分
      let decimalNum;
      //输出的中文金额字符串
      let chineseStr = "";
      //分离金额后用的数组，预定义
      let parts;
      if (money == "") {
        return "";
      }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return "";
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf(".") == -1) {
        integerNum = money;
        decimalNum = "";
      } else {
        parts = money.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        let zeroCount = 0;
        let IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
          let n = integerNum.substr(i, 1);
          let p = IntLen - i - 1;
          let q = p / 4;
          let m = p % 4;
          if (n == "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != "") {
        let decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = decimalNum.substr(i, 1);
          if (n != "0") {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
    dataURLToBlob(dataurl) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    saveImage() {
      this.$nextTick(() => {
        const targetDom = document.getElementById("picture");
        html2canvas(targetDom, {
          width: targetDom.offsetWidth, //画布的宽
          height: targetDom.offsetHeight, //画布的高
          scale: 1.0, //处理模糊问题
          useCORS: true, //开启跨域，这个是必须的
          //scrollX:0,//图片x轴的位置
          //scrollY:0,//图片Y轴的位置
          //x:0,//x轴的偏移量
          //Y:0//Y轴的便宜量
        }).then((canvas) => {
          // let blob = this.dataURLToBlob(canvas.toDataURL('image/png'))
          // this.imgUrl = URL.createObjectURL(blob)
          this.imgUrl = canvas.toDataURL("image/png");
          // console.log(this.imgUrl)
          this.isShow = false;
          let that = this;
          ImagePreview({
            images: [`${this.imgUrl}`],
            onClose() {
              that.$emit("closeReceipt");
            },
          });
          // let a = document.createElement('a')
          // a.setAttribute('href', URL.createObjectURL(blob))
          // //这块是保存图片操作  可以设置保存的图片的信息
          // a.setAttribute('download', this.receiptData.title + '.png')
          // document.body.appendChild(a)
          // a.click()
          // URL.revokeObjectURL(blob)
          // document.body.removeChild(a)
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  position: relative;
  width: 670px;
  height: 360px;
  padding: 27px 25px;
  font-family: "SimSun";
  .header {
    width: 100%;
    .header-top {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 36px;
      text-align: center;
      & > span {
        font-size: 26px;
        font-weight: bold;
        letter-spacing: 8px;
      }
      .no-box {
        position: absolute;
        right: 0;
        bottom: 0;
        color: #cf4b53;
        span:first-child {
          font-size: 16px;
          font-weight: bold;
        }
        span:last-child {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    .line-style {
      width: 33%;
      height: 6px;
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      margin: 0 auto;
    }
    .header-bottom {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      margin: 6px 0;
      text-align: center;
      div {
        line-height: 20px;
      }
    }
  }
  .detail-box {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 16px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    & > div {
      display: flex;
      width: 100%;
      padding: 10px 0;
      .text {
        text-align: left;
        border-bottom: 1px dashed #d9d9d9;
        flex: 1;
      }
      & > div:first-child {
        margin-right: 10px;
      }
    }
  }
  .bottom-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 16px 12px 0;
  }
  .seal-box {
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 150px;
    z-index: -1;
  }
}
</style>
