<template>
  <div class="contentPDt">
    <div class="details-top">缴费详情</div>
    <div class="details-content-box">
      <div
        class="Pdt-top"
        style="padding: 10px"
      >
        <div>
          <div>实收金额</div>
          <div style="padding: 5px 0; color: red">￥ {{sfData.ssje}}</div>
        </div>
      </div>
      <div style="padding: 6px 10px;text-align:left;">计费明细</div>
      <van-collapse
        v-for="(item, index) in sfData.zdList"
        v-model="activeNames[index]"
        :key="index"
      >
        <van-collapse-item
          :title="item.title"
          name="1"
          :value="'¥ '+item.zdje"
        >
          <div class="Feedetail">
            <div class="Feedetail-lie">
              <div class="lie">
                <span>编号:</span>
                {{item.htbh}}
              </div>
              <div class="lie">
                <span>名称:</span>
                {{item.xmmc}}
              </div>
            </div>
            <div class="Feedetail-lie">
              <div class="lie">
                <span>类型:</span>
                {{item.lx}}
              </div>
              <div class="lie">
                <span>日期:</span>
                {{item.zdrq}}
              </div>
            </div>
          </div>
        </van-collapse-item>
      </van-collapse>
      <div style="height:5px;"></div>
      <van-cell-group>
        <van-cell
          title="合计"
          :value="sfData.ssje"
          value-class="van-cell-one"
        />
        <van-cell
          title="状态"
          value="已缴费"
          value-class="van-cell-three"
        />
        <van-cell
          title="缴费人员"
          :value="sfData.jfry"
        />
        <van-cell
          title="收款单位"
          :value="sfData.skdw"
        />
        <van-cell
          title="缴费方式"
          :value="sfData.jffs"
        />
        <van-cell
          title="收费时间"
          :value="sfData.jfsj"
        />
      </van-cell-group>
      <div style="padding:20px 0">
        <div v-if="sfData.sfkpyh=='1'">
          <van-button
            v-if="sfData.fpzt==0 "
            round
            type="info"
            @click="$router.replace({path:'/InvoiceDetails',query:{operationType:'kp',zdjlId:sfData.id}})"
          >补开发票</van-button>
          <van-button
            v-if="sfData.fpzt==1 "
            round
            type="info"
            @click="lookInvoice"
          >查看电子发票</van-button>
        </div>
        <van-button
          v-else
          round
          type="info"
          @click="lookReceiptFun"
          style="margin-top:20px"
        >查看收据</van-button>
      </div>
      <wx-open-launch-weapp
        id="launch-btn"
        username="gh_cbf7ae2c979a"    
        :path="`/pages/index/index`"
      >
        <script type="text/wxtag-template">
          <style>
            .btn{
              width:295px;
              padding:16px;
              border-radius:50px;
              color:white;
              background-color:#1989fa;
              font-size:15px;
              border:none;
            }
            .btn:focus{
              outline:0
            }
          </style>
          <button class="btn">打印小票</button>
       </script>
      </wx-open-launch-weapp>
    </div>
    <receipt-details
      v-if="isReceipt"
      :zdid="zdid"
      @closeReceipt="closeReceipt"
    ></receipt-details>
  </div>
</template>

<script>
import { getPayDetailsApi, getInvoiceApi } from '@/reuqest/api.js'
import ReceiptDetails from '@/components/CostManager/BillQuery/receiptDetails.vue'
import wxConfigInit from '@/util/wxjssdk'

export default {
  props: ['sfjlId'],
  components: {
    ReceiptDetails,
  },
  data () {
    return {
      activeNames: [[]],
      sfData: {},
      isReceipt: false,
      zdid: '',
    }
  },
  watch: {
    sfjlId (newData) {
      this.init(newData)
    },
  },
  created () {
    let that = this
    wxConfigInit([''], window.location.href).then((wx, wxRes) => {
      var btn = document.getElementById('launch-btn')
      btn.addEventListener('launch', function (e) {
        console.log('success')
      })
      btn.addEventListener('error', function (e) {
        console.log('fail', e.detail)
      })
    })
  },
  methods: {
    init (sfjlId) {
      const loadingToast = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      })
      getPayDetailsApi({
        id: sfjlId,
      }).then((res) => {
        let data = res.data
        if(data.zdList){
          data.zdList.forEach((item) => {
            this.activeNames.push([])
          })
        }
        this.sfData = data
        loadingToast.clear()
      })
    },
    lookInvoice () {
      getInvoiceApi({ zdid: this.sfData.id }).then(res => {
        let url = res.data.qrcodepath
        if (url) {
          const a = document.createElement('a')
          document.body.appendChild(a)
          a.style.display = 'none'
          a.href = url
          a.id = 'dzfp'
          a.click()
          document.getElementById('dzfp').remove()
        }
      })
    },
    lookReceiptFun () {
      this.isReceipt = true
      // setTimeout(() => {
      this.zdid = this.sfData.id
      // }, 330)
    },
    closeReceipt () {
      this.isReceipt = false
    },
  },
}
</script>
 
<style lang="less" scoped>
.details-top {
  font-size: 16px;
  background-color: #fff;
  height: 47px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.details-content-box {
  height: calc(100vh - 47px);
  overflow-y: auto;
  /deep/.van-collapse {
    .van-cell__title {
      text-align: left;
    }
  }
}
.van-cell-three {
  color: green;
}
.van-cell-one {
  color: red;
}
.contentPDt {
  font-size: 14px;
  background-color: rgb(245, 245, 245);
  height: 100vh;
  .van-collapse {
    /deep/.van-cell__title {
      min-width: 60%;
    }
    /deep/.van-cell__value {
      color: red;
    }
  }
  .van-cell {
    text-align: left;
  }
}
.Pdt-top {
  background-color: #fff;

  text-align: center;
  .Pdt-top-bt {
    display: flex;
    justify-content: space-between;
  }
}
.Feedetail {
  .Feedetail-lie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #232323;
    padding: 2px 0;
    span {
      color: #888;
    }
  }
}
.feeTable {
  padding-top: 10px;
  table {
    width: 90%;
    text-align: center;

    td {
      padding: 2px 0;
      color: #232323;
    }
    thead {
      td {
        color: #888;
      }
    }
  }
}
.van-button__content {
  width: 65vw !important;
}
</style>